// Node_Modules
import React from "react";

// Components
import HoursOfOperation from "@/gc/store-hours/HoursOfOperation";
import RichText from "@/gc/rich-text/RichText";
import TitleHeader from "@/gc/title/TitleHeader";

// Interfaces
import { IContactHours } from "@/interfaces/contact-hours/IContactHours";

// Styles
import { StylesContactHours } from "./StylesContactHours";

const ContactHours = ({
  contactInformationItem,
  headerClass,
  hoursInformation,
  hoursInfoState,
  icons,
  marginAdjust,
  toolTipContent,
  width,
}: IContactHours) => {
  const {
    className,
    styles,
  } = StylesContactHours(width, marginAdjust);

  return (
    <div>
      {hoursInformation?.staffing?.length > 1 && (
        <>
          <div className={`divider-line ${className}`} />
          <div className={`title-container ${className}`}>
            <TitleHeader
              defaultIconSize="3rem"
              headerClass={headerClass}
              icon={icons.store}
              maxWidth="57rem"
              testId="office-hours"
              title={hoursInfoState?.officeHoursHeading}
              toolTipContent={<RichText document={toolTipContent[0]} />}
            />

          </div>
          <div className={`content-container ${className}`}>
            <HoursOfOperation
              upperCase
              hoursOfOperation={hoursInformation?.staffing}
            />
          </div>
        </>
      )}
      <div className={`divider-line ${className}`} />
      <div className={`title-container ${className}`}>
        <TitleHeader
          icon={icons.phone}
          maxWidth="48rem"
          testId="staffing-hours"
          title={hoursInfoState?.phoneHoursHeading}
          toolTipContent={<RichText document={toolTipContent[1]} />}
        />
      </div>
      <div className={`content-container ${className}`}>
        <HoursOfOperation
          upperCase
          hoursOfOperation={hoursInformation?.phone}
        />
        <div className={`root ${className}`}>
          <div className={`phone-number-box ${className}`}>
            <div className={`phone-number-box-row ${className}`}>
              <span
                className={`phone-number-label uppercase  ${className}`}
                data-testid="phone-heading"
              >
                {`${contactInformationItem.phoneNumberLabel}`}
              </span>
              <span
                className={`phone-number-text ${className}`}
                data-testid="phone-number"
              >
                {`${contactInformationItem.phoneNumber}`}
              </span>
            </div>
          </div>
        </div>
      </div>
      {styles}
    </div>
  );
};

export default ContactHours;
