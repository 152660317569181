// Node modules
import css from "styled-jsx/css"

// Theme
import theme from "@/theme";

export const StylesHoursOfOperation = (rootWidth?: string) => {
  return css.resolve`
 
      .days-text{
        display:flex;
        font-size: 2.4rem;
        font-weight: 600;
        justify-content: flex-start;
        margin: 0;
        min-width: 34rem;
        padding-right: 2rem;
        text-transform: none;
      }
      
      .hours-text{
      display:flex;
        font-size: 3rem;
        margin: 0;
      }

      .root{
        color: ${theme.fontColors.primaryGray};
        font-family: ${theme.fontFamilies.arial};
        width: ${rootWidth}
      }
      .schedule{
        align-items: center;
        display: flex;
        flex-direction: column;
        line-height: 1.6;
        
      }

      .schedule-row{
        display: grid; 
        font-size: 4rem;
        grid-template-columns: repeat(2, 1fr); 
        grid-column-gap: 10px;
      }

      .time{
        font-size: 2.4rem;
        font-weight: 400;
      }

      .title{
        margin: 4.5rem;
        font-family: ${theme.fontFamilies.ramaGothicSemiBold};
        font-weight: 700;
        text-align: center;
      }
      
      .uppercase {
        text-transform: uppercase;
      }
    `
}
