import css from "styled-jsx/css"
import styled from "@emotion/styled";

// theme
import theme from "@/theme";

export const StylesContactHours = (rootWidth?: string, marginAdjust?:string) => {
  return css.resolve`
  .access-info{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
  }
  
  .container{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%;
  }
  
  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5% 0 5% 5%;
    margin-bottom:3.2rem
  }
  .divider-line{
    border-bottom: .03rem solid ${theme.borderColors.lightGray};
    width: 90%;
    margin: auto;
  }
  
  .features-container{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 5rem;
    padding: 5% 0 5% 5%;
    width: 90%;
  }
  
  .features-list{
    column-gap: 10rem;
    display: flex;
    font-family: ${theme.fontFamilies.arial};
    font-size: 3.2rem;
    justify-content: flex-end;
    line-height: 5rem;
    text-transform: capitalize;
    padding: 3% 0% 0% 20%;
  }
  
  .hours-container{
    padding: 7%;
    width: 75%;
  }
  
  li.feature-item{
    margin-left: 4rem;
  }

  .phone-number-box{
    align-items: center;
    display: flex;
    flex-direction: column;
    line-height: 1.6;
  }

  .phone-number-box-row{
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 4rem;
    line-height: 1.6;
    width:100%;
    }

  .phone-number-label{
    display: flex;
    font-size: 2.5rem;
    font-weight: 600;
    justify-self:flex-start;
    min-width: 35rem;
    margin-left:${marginAdjust}rem;
  }
  .phone-number-text{
  display: flex;
  font-size: 3rem;
  }

  .root{
    color: ${theme.fontColors.primaryGray};
    font-family: ${theme.fontFamilies.arial};
    width: ${rootWidth}
  }
  svg.title-icon,
  .features-icon.svg-inline--fa.fa-w-16{
    font-size: 6.3rem;
    margin-right: 2rem;
  }

  .title-span{
    margin: 0rem 2rem 0rem 0rem;
  }
  ul.feature-ul{
    columns: 2;
    list-style: disc;
  }
`
}
export const StyledAccessHoursContent = styled.div`
  & ul{
    list-style: disc;
    padding: 1rem 5rem;
    & li {
      padding: 0.5rem 0rem;
    }
  }
`
export const StyledOfficeHoursContent = styled.div`
  & p{
    padding: 1rem 1.5rem;
  }
`
