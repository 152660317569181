// Components
import FontAwesomeIcon from "@/gc/icon/FontAwesomeIcon";

// Themes
import theme from "@/theme";

// FontAwesome icons
import {
  faArrowLeft,
  faTimes
} from "@fortawesome/pro-solid-svg-icons";

// Types
import IBottomModalProps from "@/interfaces/global-components/modal/IBottomModalProps";

const BottomModal = ({
  backButtonOnClick,
  children,
  height,
  id,
  setShowModal,
  showBackButton,
  subTitle,
  testId,
  title,
}: IBottomModalProps) => {
  const onAnimationCompleted = modalContent => {
    modalContent.classList.remove("hide");
    modalContent.removeEventListener("animationend", onAnimationCompleted.bind(this, modalContent));
    setShowModal(false);
  };

  const closeModal = () => {
    const modalContent = document.getElementsByClassName("modal-content")[0];
    modalContent.classList.add("hide");

    modalContent.addEventListener("animationend", onAnimationCompleted.bind(this, modalContent));
  };

  return (
    <>
      <div
        className="modal-container"
        data-testid={testId}
      >
        <div
          className="modal-backdrop"
          data-testid="backdrop"
          onClick={() => closeModal()}
        />
        <div
          className="modal-content"
          data-testid="content"
        >
          <div className="header-button-container">
            <span className="button-container button-back">
              {showBackButton && (
                <button
                  className="close-modal-button"
                  data-testid="back-button"
                  id={id}
                  onClick={backButtonOnClick || closeModal}
                >
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    size="2x"
                  />
                </button>
              )}
            </span>

            <div
              className="header"
              data-testid="modal-header"
            >
              <h1 className="title">{title}</h1>
            </div>
            <span className="button-container button-close">
              <button
                className="close-modal-button"
                data-testid="close-button"
                onClick={() => closeModal()}

              >
                <FontAwesomeIcon
                  icon={faTimes}
                  size="2x"
                />
              </button>
            </span>
          </div>
          {subTitle && (
            <div
              className="subheader"
              data-testid="modal-subheader"
            >
              {subTitle}
            </div>
          )}
          <div
            className="modal-body"
            data-testid="modal-body"
          >
            {children}
          </div>
        </div>

      </div>
      <style jsx>{`
        @keyframes slideUp {
            0% { bottom: -83% }
            100% { bottom: 0; }
        }

        @keyframes slideDown {
            0% { bottom: 0% }
            100% { bottom: -83%; }
        }

        .button-container{
          display: block;
          flex-basis: 15%;
          font-size: 3rem;
          height: fit-content;
          padding: 0.5rem 0 0 0;
          text-align: center;
        }

        .button-back{
          order: 1;
          padding-left: 2rem;
        }

        .button-close{
          order: 3;
          padding-right: 2rem;
        }
        .close-modal-button{
          background: transparent;
          border: none !important;
          color: inherit;
          font-size: inherit;
        }

        .header {
          color: ${theme.fontColors.black};
          flex-basis: 70%;
          order:2;
          padding: 0 0 0 0;
          text-align: center;
        }

        .header-button-container {
          align-content: center;
          display: flex;
          flex-flow: wrap;
          justify-content: center;
          padding: 5rem 0 2rem 0px;
          position: relative;
          width: 100%;
        }

        .modal-backdrop {
          backdrop-filter: blur(40px);
          background: rgba(151, 151, 151, 0.5); /* This is actually #979797 (fiftyGray) but had to use rgba values */
          height: 100%; 
          left: 0;
          overflow: hidden; 
          position: fixed;
          top: 0;
          width: 100%; 
          z-index: 1;
        }

        .modal-body{
          text-align: center;
          width: 100%;
        }

        .modal-container {
          height: 100%; 
          left: 0;
          overflow: hidden; 
          position: fixed;
          top: 0;
          width: 100%; 
          z-index: 3;

        }

        .modal-content {
          animation: slideUp forwards linear 250ms;
          background: ${theme.brandColors.white};
          border-radius: 20px 20px 0 0;
          bottom: -83%;
          display: flex;
          flex-direction: column;
          height: ${height ?? "83%"};
          overflow: auto;
          position: fixed;
          width: 100%;
          z-index: 2;
        }
        
        .modal-content.hide {
          animation: slideDown forwards linear 250ms;
        }

        .subheader{
          font-size: 3.6rem;
          padding: 2rem 0 3rem 0;
        }
        .title{
          padding: 0px 8rem;
        }
        `}
      </style>
    </>
  );
};

export default BottomModal;
