// components
import FontAwesomeIcon from "@/gc/icon/FontAwesomeIcon";
import Tooltip from "@/gc/tool-tip/ToolTip";

// InterFace
import ITitleHeaderProps from "@/interfaces/title-header/ITitleHeaderProps";

// Styles
import { StylesTitleHeader } from "./StylesTitleHeader";

// Theme
import theme from "@/data/theme";

const TitleHeader: React.FC<ITitleHeaderProps> = ({
  childrenClassName,
  containerPadding,
  icon,
  iconSize,
  isChildrenBlockTag,
  maxWidth,
  testId,
  title,
  toolTipContent,
}) => {
  const {
    className,
    styles,
  } = StylesTitleHeader(containerPadding);

  return (
    <div className={`title-container ${className}`}>
      <h3
        className={`title-text ${className}`}
        data-testid={testId}
      >
        <span>
          <FontAwesomeIcon
            className="title-icon"
            color={theme.brandColors.primaryGreen}
            icon={icon}
            size={iconSize}
          />
          <span className={`title-span ${className}`}>{title?.toUpperCase()}</span>
        </span>
        {toolTipContent && (
          <Tooltip
            childrenClassName={childrenClassName}
            defaultIconSize="3rem"
            isChildrenBlockTag={isChildrenBlockTag}
            maxWidth={maxWidth || "57rem"}
            toolTipContent={toolTipContent}
          />
        )}
      </h3>
      {styles}
    </div>
  );
};

export default TitleHeader;
