// Enums
import { Brand } from "@/enums/Brand";

// Types
import { IContactInformationFields } from "@/contentful-types";
import { IContactInformationModified } from "@/src/models/contentful/IContactInformationModified";
import IDeviceInformation from "@/interfaces/device/IDeviceInformation";

const getCustomerServiceContactInformationItem = (contactInformationList: IContactInformationModified[], deviceInformation: IDeviceInformation, useTwilioFlex: boolean): IContactInformationFields =>
  getPhoneNumberPerBrandAndDepartment(contactInformationList, "CustomerService", deviceInformation, useTwilioFlex);

const getSalesContactInformationItem = (contactInformationList: IContactInformationModified[], deviceInformation: IDeviceInformation, useTwilioFlex: boolean): IContactInformationFields =>
  getPhoneNumberPerBrandAndDepartment(contactInformationList, "Sales", deviceInformation, useTwilioFlex);

const getPhoneNumberPerBrandAndDepartment = (contactInformationList: IContactInformationModified[], department: "CustomerService" | "Sales", deviceInformation: IDeviceInformation, useTwilioFlex: boolean): IContactInformationFields => {
  const kioskBrand: string = deviceInformation.brand ?? Brand.ESS;

  let contactInformationItem = contactInformationList
    .filter(item => item.fields.enabled
      && item.fields.brand === kioskBrand
      && ((useTwilioFlex && item.fields.callFeature === "TWILIO_FLEX")
        || (!useTwilioFlex && item.fields.callFeature === "TWILIO_VOICE"))
      && item.fields.department?.includes(department))[0];

  if (!contactInformationItem) {
    contactInformationItem = contactInformationList[0];
  }

  return contactInformationItem?.fields;
}

export {
  getCustomerServiceContactInformationItem,
  getSalesContactInformationItem
}
