// Styles
import { StylesHoursOfOperation } from "./StylesHoursOfOperation";

// Types
import { IHoursOfOperation } from "@/interfaces/hours-of-operation/IHoursOfOperation";
import { IHoursOfOperationProps } from "@/interfaces/global-components/store-hours/IHoursOfOperationProps";

const HoursOfOperation = ({
  hoursOfOperation,
  upperCase,
  title,
  width,
}: IHoursOfOperationProps) => {
  const {
    className,
    styles,
  } = StylesHoursOfOperation(width);

  const scheduleRowIsValid = (row: IHoursOfOperation) => {
    return row && row.hours && row.label;
  }
  return (
    <div
      className={`root ${className}`}
    >
      {title && (<h3 className={`title ${className}`}>{title}</h3>)}
      <div className={`schedule ${className}`}>
        {hoursOfOperation?.map((row: IHoursOfOperation, index: number) => {
          return (
            <div
              className={`schedule-row ${className}`}
              key={`schedule-row-${index}`}
            >
              {scheduleRowIsValid(row) && (
                <>
                  <span
                    className={`days-text ${upperCase
                      ? "uppercase"
                      : ""} ${className}`}
                  >{row.label}</span>
                  <span className={`hours-text ${className}`}>{row.hours}</span>
                </>
              )}

            </div>
          )
        })}
      </div>
      {styles}
    </div>
  );
};

export default HoursOfOperation;
