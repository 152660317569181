// Node Modules
import css from "styled-jsx/css"

// Theme
import theme from "@/theme";

export const StylesTitleHeader = (containerPadding?: string) => {
  return css.resolve`
    .access-hours-container {
      display: flex;
      justify-content: center;
      width: 100%;
      padding:5rem 0 0 0;
    }
    
    span.title-span {
      margin: 0 2rem;
      }
    
    .title-container{
      display: flex;
      justify-content: center;
      padding: ${containerPadding ?? "5rem 0 1rem 0"};
      width: 100%;
    }
      
    .title-text {
      align-items: center;
      align-content: center;
      color: ${theme.borderColors.black};
      display: flex;
    }
    `
}

