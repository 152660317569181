// Node Modules
import Image from "next/legacy/image";

// Components
import Button from "@/gc/button/Button";
import FontAwesomeIcon from "@/gc/icon/FontAwesomeIcon";

// Scripts
import { getAccessibilitySize } from "@/scripts/accessibilityHelper";

// Themes
import theme from "@/theme";

// Types
import IPrimaryButtonGroupProps from "@/interfaces/global-components/button/IPrimaryButtonGroupProps";

const PrimaryButtonGroup = ({
  options,
}: IPrimaryButtonGroupProps) => {
  let isDefaultZoomValue: boolean;
  if (typeof window !== "undefined") {
    const currentZoomValue = getAccessibilitySize(document).zoom;
    isDefaultZoomValue = currentZoomValue == 1;
  }

  return (
    <>
      <div
        className="options-container"
        data-testid="options-container"
      >
        {options.map(option => {
          return option.isEnabled !== false
            ? (
              <Button
                dataLayerEventName={option.dataLayerEventName}
                height="26.3rem"
                href={option.link}
                id={option.id}
                isExternalHref={option.isExternalLink}
                key={option.label}
                radius="4rem"
                testId={option.testId}
                width="77%"
                onClick={option.onClick}
              >
                <div className="option-content">
                  <span className="label-description-container">
                    <p
                      className="option-label"
                      data-testid={`option-label-${option.testId}`}
                    >
                      {option.label}
                    </p>
                    <p
                      className="option-description"
                      data-testid={`option-description-${option.testId}`}
                      style={option.width
                        ? {
                          width: option.width,
                        }
                        : {}}
                    >
                      {option.description}
                    </p>
                  </span>
                  {
                    option.icon && (
                      <div
                        className="icon-container"
                        data-testid={`icon-container-${option.testId}`}
                        style={option.icon.fontSize
                          ? {
                            fontSize: option.icon.fontSize,
                          }
                          : {}}
                      >
                        <FontAwesomeIcon
                          color={option.icon.color}
                          icon={option.icon.iconDefinition}
                          size={option.icon.size}
                        />
                      </div>
                    )
                  }
                  {
                    option.image && (
                      <span className="image-container">
                        {
                          option.image.useNextImage && (
                            <Image
                              alt={option.image.alt}
                              data-testid={`${option.testId}-image`}
                              height={option.image.height}
                              layout="fixed"
                              src={option.image.src}
                              width={option.image.width}
                            />
                          )
                        }
                        {
                          !option.image.useNextImage && (
                            <img
                              alt={option.image.alt}
                              data-testid={`${option.testId}-image`}
                              height={option.image.height}
                              src={option.image.src}
                              width={option.image.width}
                            />
                          )
                        }
                      </span>
                    )
                  }
                </div>
              </Button>
            )
            : null
        })}
      </div>

      <style jsx>
        {`

            .icon-container { 
              display: flex;
              align-items: center;
              justify-content: center;
              width: 25%;
            }

            .image-container { 
              position: relative;
              width: 30%;
            }

            .label-description-container {
              text-align: left;
              width: 100%;
            }
            
            .option-content {
              display: flex;
              padding: 3rem 6.5rem;
              width: 100%;
              align-items: center;
            }

            .option-description {
              font-size: 3.2rem;
              line-height: 3.2rem;
              font-weight: normal;
              padding-top: 1.5rem;
              white-space: pre-wrap;
              width: ${isDefaultZoomValue
            ? "70%"
            : "75%"};
            }

            .option-label {
              font-family: ${theme.fontFamilies.ramaGothicSemiBold};
              font-size: 6rem;
              line-height: 6rem;
              text-transform: uppercase;
              width: ${isDefaultZoomValue
            ? "80%"
            : "96%"};
            }

            .options-container {
              align-items: center;
              display: flex;
              flex-direction: column;
              padding-top: 3rem;
            }
          `}
      </style>
    </>

  );
};

export default PrimaryButtonGroup;
